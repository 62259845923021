import { useCallback, useEffect, useState, useRef } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import axios from "axios";

import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import CarouselTotal from "../Sfv_Carousel";
import SideBar from "../SideBar";
import CustomProgressBar from "../ProgressBar";
import ProgressInfo from "../ProgressBar/progressInfo";
import RadarChart from "../Graph/radar";
import KPICards from "../KPICards";
import InfoTable from "../InfoTable";
import RiskTableDialog from "./RiskDialog";

import API_URL from "../../utils/API_URL";

import { useDispatch, useSelector } from "react-redux";
import { setChattingStatus } from "../../slice/authSlice";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const DrawerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const DrawerContent = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledTable = styled("table")(({ theme }) => ({
  width: "100%",
  borderCollapse: "collapse",
  "tbody tr td": {
    padding: "0.2rem 0.4rem",
  },
  "tbody tr td:first-of-type": {
    color: "#ffffff",
    width: "50%",
  },
  "tbody tr td:last-of-type": {
    color: "#878a99",
  },
}));

function SfvPage() {
  const { unityProvider, unload, sendMessage } = useUnityContext({
    loaderUrl: "http://211.45.162.147/Build/WebGL.loader.js",
    dataUrl: "http://211.45.162.147/Build/WebGL.data.gz",
    frameworkUrl: "http://211.45.162.147/Build/WebGL.framework.js.gz",
    codeUrl: "http://211.45.162.147/Build/WebGL.wasm.gz",
    streamingAssetsUrl: `http://211.45.162.147/StreamingAssets`,
  });
  const dispatch = useDispatch();
  const unityContainerRef = useRef(null);
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);
  const [currentMchId, setCurrentMchId] = useState("");
  const [radarData, setRadarData] = useState([0, 0, 0, 0, 0, 0]);
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [indicatorData, setIndicatorData] = useState({});
  const [riskTableShow, setRiskTableShow] = useState(false);
  const [riskTableData, setRiskTableData] = useState({});
  const { chattingStatus } = useSelector((state) => state.auth);
  const fetchData = async (mchId) => {
    try {
      const response = await axios.post(API_URL + "/drawer/getAssetData", {
        mchId,
      });

      if (response.status === 404) {
        setDrawerContent(null);
        setRadarData([0, 0, 0, 0, 0, 0]);
        setIndicatorData({});
        return;
      }

      const { asset, radarData, indicatorData } = response.data;

      setDrawerContent(asset);
      setRadarData(radarData);
      setIndicatorData(indicatorData);
      console.log(asset);
    } catch (error) {
      console.error("Error fetching data:", error);
      setDrawerContent(null);
      setRadarData([0, 0, 0, 0, 0, 0]);
      setIndicatorData({});
    }
  };

  const fetchRiskTableData = async (mchId) => {
    try {
      const response = await axios.post(API_URL + "/drawer/getRiskDialogData", {
        mchId,
      });
      if (response.status === 404) {
        setRiskTableData({});
        return;
      }
      const { riskData } = response.data;

      setRiskTableData(riskData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setRiskTableData({});
    }
  };

  const SFV_UpdateData = useCallback((mchId, pickingData) => {
    console.log("MchID:", mchId, "PickingData:", pickingData);
    if (mchId !== "" && pickingData.includes('"type":21')) {
      setCurrentMchId(mchId);
      fetchData(mchId);
      setDrawerOpen(true);
    }
  }, []);

  const SFV_UpdateStatus = useCallback((type, status) => {
    dispatch(
      setChattingStatus({
        type,
        status,
      })
    );
    // setStatus(`${type}: ${status}`);
  }, []);

  const updateCanvasSize = () => {
    const unityContainer = unityContainerRef.current;
    if (unityContainer) {
      const canvas = unityContainer.querySelector("canvas");
      if (canvas) {
        const newWidth = unityContainer.clientWidth;
        const newHeight = unityContainer.clientHeight;
        const resolutionWidth = newWidth * window.devicePixelRatio;
        const resolutionHeight = newHeight * window.devicePixelRatio;

        // 캔버스 크기 조정
        // canvas.style.width = `${newWidth}px`;
        // canvas.style.height = `${newHeight}px`;

        // 유니티 WebGL 내부 해상도 업데이트
        canvas.width = resolutionWidth;
        canvas.height = resolutionHeight;
      }
    }
  };

  useEffect(() => {
    const unityContainer = unityContainerRef.current;

    const handleDevicePixelRatioChange = () => {
      // console.log("Device Pixel Ratio:", window.devicePixelRatio);
      setDevicePixelRatio(window.devicePixelRatio);
      updateCanvasSize();
    };

    const mediaMatcher = window.matchMedia(
      `screen and (resolution: ${window.devicePixelRatio}dppx)`
    );
    mediaMatcher.addEventListener("change", handleDevicePixelRatioChange);

    updateCanvasSize();

    const observer = new ResizeObserver(() => {
      updateCanvasSize();
    });

    if (unityContainer) {
      observer.observe(unityContainer);
    }

    return () => {
      mediaMatcher.removeEventListener("change", handleDevicePixelRatioChange);
      if (unityContainer) {
        observer.unobserve(unityContainer);
      }
    };
  }, [devicePixelRatio]);

  useEffect(() => {
    window.SFV_UpdateData = SFV_UpdateData;
    window.SFV_UpdateStatus = SFV_UpdateStatus;
    return () => {
      delete window.SFV_UpdateData;
      delete window.SFV_UpdateStatus;
    };
  }, [SFV_UpdateData, SFV_UpdateStatus]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const drawerArea = document.querySelector(".MuiDrawer-paperAnchorRight");
      const dialogArea = document.querySelector(".MuiDialog-paper");
      if (
        drawerOpen &&
        drawerArea &&
        !drawerArea.contains(event.target) &&
        (!dialogArea || !dialogArea.contains(event.target))
      ) {
        setDrawerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [drawerOpen]);
  useEffect(() => {
    if (!drawerOpen) {
      sendMessage("Canvas", "ClearMachineData", "Picking");
    }
  }, [drawerOpen, sendMessage]);
  useEffect(() => {
    const preventDefault = (event) => {
      event.preventDefault();
    };

    const sfvPageElement = document.getElementById("sfv-page");
    if (sfvPageElement) {
      sfvPageElement.addEventListener("contextmenu", preventDefault);
      sfvPageElement.addEventListener("dragstart", preventDefault);
      sfvPageElement.addEventListener("selectstart", preventDefault);
    }

    return () => {
      if (sfvPageElement) {
        sfvPageElement.removeEventListener("contextmenu", preventDefault);
        sfvPageElement.removeEventListener("dragstart", preventDefault);
        sfvPageElement.addEventListener("selectstart", preventDefault);
      }
    };
  }, []);
  const handleIndicatorClick = useCallback((indicatorName) => {
    setSelectedIndicator(indicatorName);
  }, []);

  const handleRiskDialogOpen = (mchId) => {
    fetchRiskTableData(mchId);
    setRiskTableShow(true);
  };

  const handleRiskDialogClose = () => {
    setRiskTableShow(false);
  };

  return (
    <div id="sfv-page">
      <SideBar unload={unload} />
      <Box
        sx={{
          position: "absolute",
          top: "20px",
          left: "80px",
          right: 0,
          display: "flex",
          justifyContent: "center",
          zIndex: 10,
        }}
      >
        <KPICards />
      </Box>
      <div
        ref={unityContainerRef}
        style={{
          width: "calc(100vw - 80px)",
          height: "100vh",
          overflow: "hidden",
          marginLeft: "80px",
        }}
      >
        <Unity
          unityProvider={unityProvider}
          style={{ width: "100%", height: "100%" }}
          // 중요한 옵션, 디폴트가 true인데 WebGL 해상도를 캔버스 사이즈에 강제로 일치시킴
          matchWebGLToCanvasSize={false}
        />
        <Drawer
          anchor="right"
          open={drawerOpen}
          ModalProps={{
            keepMounted: true,
            hideBackdrop: true,
          }}
          sx={{
            "& .MuiDrawer-paper": {
              backgroundColor: "#121212",
              opacity: 0.85,
              color: "#ffffff",
              width: 350,
            },
          }}
        >
          <Box
            id="drawer-header"
            sx={{
              paddingY: "0.5rem",
              paddingX: "0.75rem",
              backgroundColor: "#000000",
            }}
            role="presentation"
          >
            <DrawerHeader>
              <Box sx={{ margin: 0, fontSize: "1.5em", fontWeight: 500 }}>
                {drawerContent ? drawerContent.asset_name : "장비 미연동"}
              </Box>
              <Box
                sx={{
                  margin: 0,
                  fontSize: "1.2em",
                  fontWeight: 400,
                  color: "#878a99",
                }}
              >{`연동ID: ${currentMchId}`}</Box>
              <IconButton
                onClick={() => setDrawerOpen(false)}
                sx={{ color: "#ced4da" }}
              >
                <CloseIcon />
              </IconButton>
            </DrawerHeader>
          </Box>
          <Divider />
          <Box
            id="progress-bar"
            sx={{ padding: 3, opacity: 1 }}
            role="presentation"
          >
            <CustomProgressBar
              startTime={drawerContent?.start_time}
              endTime={drawerContent?.end_time}
            />
            <ProgressInfo
              startTime={drawerContent?.start_time || "정보없음"}
              endTime={drawerContent?.end_time || "정보없음"}
            />
          </Box>
          <Divider />
          <Box
            id="drawer-title"
            sx={{ padding: 3, backgroundColor: "#000000" }}
            role="presentation"
          >
            <DrawerTitle>
              <Box sx={{ margin: 0, fontSize: "1.2em", fontWeight: 500 }}>
                생산 정보
              </Box>
            </DrawerTitle>
          </Box>
          <Divider />
          <Box
            id="drawer-content"
            sx={{ padding: "0.4rem" }}
            role="presentation"
          >
            <DrawerContent>
              <StyledTable>
                <tbody>
                  <tr>
                    <td>작업지시번호</td>
                    <td>{drawerContent?.work_order || "장비 미연동"}</td>
                  </tr>
                  <tr>
                    <td>품명</td>
                    <td>{drawerContent?.item || "장비 미연동"}</td>
                  </tr>
                  <tr>
                    <td>수량</td>
                    <td>{drawerContent?.qty || "장비 미연동"}</td>
                  </tr>
                  <tr>
                    <td>공정명</td>
                    <td>{drawerContent?.process_name || "장비 미연동"}</td>
                  </tr>
                  <tr>
                    <td>작업자</td>
                    <td>{drawerContent?.worker || "장비 미연동"}</td>
                  </tr>
                </tbody>
              </StyledTable>
            </DrawerContent>
          </Box>
          <Divider />
          <Box
            id="drawer-title"
            sx={{ padding: 1, backgroundColor: "#000000" }}
            role="presentation"
          >
            <RadarChart
              data={radarData}
              onIndicatorClick={handleIndicatorClick}
            />
          </Box>
          <Divider />
          <InfoTable
            indicatorData={indicatorData}
            selectedIndicator={selectedIndicator}
            onButtonClick={() => handleRiskDialogOpen(currentMchId)}
          />
        </Drawer>
      </div>
      <CarouselTotal sendMessage={sendMessage} />
      <RiskTableDialog
        open={riskTableShow}
        onClose={handleRiskDialogClose}
        data={riskTableData}
        assetInfo={drawerContent?.asset_name || "장비 미연동"}
      />
    </div>
  );
}

export default SfvPage;
